'use client';

import TopNavigation, { TopNavigationProps } from '@pickleballinc/ui/components/pickleball-app/TopNavigation';

// import { useModalSSO } from '@/lib/modal-sso';

export const TopNavigationWrapper = (props: TopNavigationProps) => {
	// const { setActive } = useModalSSO();

	return <TopNavigation {...props} onSSOClick={undefined} />;
};
